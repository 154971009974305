import { createRouter, createWebHistory } from "vue-router";

//catchall 404
import NotFound from "../views/NotFound.vue";

const routes = [
  { path: "/", name: "Home", component: () => import("@/views/Home") },
  { path: "/about", name: "About", component: () => import("@/views/About") },


  { path: "/faq", name: "Faq", component: () => import("@/views/Faq") },
  { path: "/blog", name: "Blog", component: () => import("@/views/Blog") },
  {
    path: "/blog-content/:title",
    name: "BlogContent",
    component: () => import("@/views/BlogContent"),
    props: true
  },

  { path: "/terms",
    redirect: (to) => {
      window.location.href = "https://drive.google.com/file/d/1qUofCRG59GKtwkrnpmFc1FAMQiDotVgM/view?usp=drive_link"
    }
  },
  {
    path: "/privacy-policy",
    redirect: (to) =>{
      window.location.href = "https://drive.google.com/file/d/1QWf-BdOs28023ypuX2QROj90yPFDVugb/view?usp=drive_link"
    }
  },

  { path: "/paia", redirect: (to) =>{
    window.location.href = "https://drive.google.com/file/d/1oIOvG-MRfTfQVJor6uU22FAvDvjkM3vy/view?usp=drive_link"
  } },

  { path: "/privacy",
    redirect: (to) =>{
    window.location.href = "https://drive.google.com/file/d/1QWf-BdOs28023ypuX2QROj90yPFDVugb/view?usp=drive_link"
  } },

  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
