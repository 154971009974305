import axios from "axios";
import spHeader from "./super-authHeader";
import { API } from "@/util/APIroute";
import { ToastAlert } from "@/util/SweetAlertUtl";

// const API = "https://producemart.herokuapp.com/";
//const API = "http://localhost:3000/";

class AuthService {
  async login(user)
    {
    const response = await axios.post(API + "/signin", {
      email: user.email,
      password: user.password,
    });
    if (response.data.token) {
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          firstname: response.data.user.firstname,
          lastname: response.data.user.lastname,
          token: response.data.token,
        })
      );
    }
    return response.data;
  }

  async loginSuperAdmin(user) {
    const response = await axios.post(API + "/adminLogin", {
      email: user.email,
      password: user.password,
    });
    if (response.data.token) {
      sessionStorage.setItem(
        "SuperAdmin",
        JSON.stringify({
          token: response.data.token,
        })
      );
    }
    return response.data;
  }

  async logout() {
    const userId = JSON.parse(sessionStorage.getItem("user"))._id
    sessionStorage.removeItem("user");
    // localStorage.removeItem("token");
    return axios.get(
      `${API}/logout/${userId}`
    )
  }

  async logoutAdmin() {
    const userId = JSON.parse(sessionStorage.getItem("user"))._id
    sessionStorage.removeItem("SuperAdmin");
    sessionStorage.removeItem("user");
    // localStorage.removeItem("token");
    return axios.get(
      `${API}/logout/${userId}`
    )
  }

  register(user) {
    return axios.post(API + "/register-buyer", {
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      email: user.email,
      phone_no: user.phone_no,
      password: user.password,
      company_name: user.company_name,
      company_reg_number: user.company_reg_number,
      country: user.country,

    });
  }
  supplier(user) {
    return axios.post(API + "/register-supplier", {
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      email: user.email,
      phone_no: user.phone_no,
      password: user.password,
      company_name: user.company_name,
      country: user.country,
    });
  }

  verify(id, token) {
    return axios.get(
      `${API}/email-verification/${id}/${token}`
    );
  }

  getOrganizers() {
    return axios.get(API + "/dashboard/profile", { headers: spHeader() });
  }
}

export default new AuthService();
